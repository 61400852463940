.career-summary__header {
  display: flex;
}

.career-summary__header-block {
  margin: 1em 0;
}

.career-summary__key {
  margin-top: 2em;
  display: flex;
  flex-wrap: wrap;
}

.career-summary__key-heading {
  font-size: 12px;
  display: block;
  width: 100%;
  margin: 0 0 8px;
}

.key {
  padding: 5px 8px;
  margin-right: 10px;
  font-weight: bold;
}

.key__meta {
  font-weight: normal;
  font-size: 14px;
  font-style: italic;
  display: block;
}

.key--current {
  background-color: #7DA12F;
}

.key--graded {
  background-color: #5E7B21;
}

.key--core {
  background-color: #11a9a1;
}

.key--skills {
  background-color: #fb6500;
}

table.milestone-table {
  margin: 0;
  border-spacing: 0;
  text-align: center;
  width: 100%;
  border: 3px solid #4c5b67;
}

table.milestone-table td {
  height: 30px;
}

table.milestone-table td.breaker {
  height: 3px;
}

table.milestone-table td:nth-of-type(3n+1) {
  border-left: 3px solid #4c5b67;
}

table.milestone-table td.tier-label:nth-of-type(1n+1) {
  border-left: 3px solid #4c5b67;
}

.level-label {
  width: 6.6666666%;
  background-color: transparent;
  border-right: 1px solid #4c5b67;
  border-bottom: 1px solid #4c5b67;
}

.tier-label {
  background-color: transparent;
  border-right: 1px solid #4c5b67;
  border-bottom: 1px solid #4c5b67;
}

.current-full {
  background-color: #7da12f;
  border-left: 1px dotted #4c5b67;
}

.current-empty {
  background-color: #7da12f;
  opacity: 0.15;
  border-left: 1px dotted #4c5b67;
}

.graded-full{
  background-color: #5e7B21;
  border-left: 1px dotted #4c5b67;
}

.graded-empty{
  background-color: #5e7B21;
  opacity: 0.15;
  border-left: 1px dotted #4c5b67;
}

.core-full{
  background-color: #11a9a1;
  border: 0;
}

.core-empty{
  background-color: #11a9a1;
  opacity: 0.15;
  border: 0;
}

.skills-full {
  background-color: #fb6500;
  border: 0;
}

.skills-empty {
  background-color: #fb6500;
  opacity: 0.15;
  border: 0;
}

.graded-high::after {
  font-size: 14px;
  content:'Your grade is higher than your level.';
  display: block;
  font-style: italic;
  font-weight: normal;
}

.graded-low::after {
  font-size: 14px;
  content:'Your grade is lower than your level.';
  display: block;
  font-style: italic;
  font-weight: normal;
}
