.export-button {
  float: right;
  color: #ffffff;
  border: solid 1px #ffffff;
  display: inline;
  padding: 4px;
  margin: 4px;
  width: 120px;
  font: 12px helvetica;
  border-radius: 8px;
}

.export-button:hover {
  background-color: #11a9a1;
}
