.bounding-table {
  width: 100%;
}

.section {
  margin-top: 2.5em;
}

.section__title {
  margin-bottom: 5px;
}

.section__subtitle {
  text-align: left;
  padding-bottom: 5px;
  margin-top: .5em;
  margin-bottom: 10px;
  border-bottom: 1px dotted #e1e9ef;
}

.section p {
  margin-top: 0;
  font-size: 0.8em;
  width: 360px;
}

table {
  width: 360px;
  margin-bottom: 10px;
  table-layout: fixed;
}

.track-selector-arrow {
  text-align: center;
  color: #000;
  padding: 2px;
  font-weight: normal;
  font-size: 11px;
  border-radius: 3px;
  border: 2px solid transparent;
}

.track-selector-arrow:hover {
  border: 2px solid #555;
}

.track-selector-label {
  text-align: center;
  font-size: 12px;
}

.track-selector-value {
  height: 50px;
  text-align: center;
  background: #eee;
  color: #000;
  font-weight: bold;
  font-size: 24px;
  border-radius: 3px;
  background-color: #11a9a1;
}

.track-background-foundational {
  background-color: #11a9a1;
}

.track-light-background-foundational {
  background-color: #aad9d1;
}

.track-background-delivering {
  background-color: #ffc59e;
}

.track-light-background-delivering {
  background-color: #ffe5ce;
}

.track-background-strengthening {
  background-color: #ff944d;
}

.track-light-background-strengthening {
  background-color: #ffd4ad;
}

.track-background-supporting {
  background-color: #fb6500;
}

.track-light-background-supporting {
  background-color: #ffa46d;
}

button {
  background-color: transparent;
  cursor: pointer;
  border: 0;
  width: 100%;
  font: inherit;
}

/* Tablet */
@media screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (-webkit-min-device-pixel-ratio: 2) {
  table, .section p {
    width: 330px;
  }
}

