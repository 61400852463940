.version-bar {
  background-color: #ff6644;
  color: #eeeeee;
  font-weight: bold;
  display: inline;
  float: left;
  padding: 4px 8px 4px 8px;
  margin: 4px;
  font: 12px helvetica;
  border-radius: 8px;
}

.version-bar a {
  color: #eeeeee;
  text-decoration: underline;
}

.version-bar a:hover {
  color: #d1d2d3;
}