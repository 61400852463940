.track__header {
  padding: 1em 0 .25em;
}

.track__title {
  margin-top: 0;
  margin-bottom: 5px;
}

.track__description {
  margin-top: 10px;
  margin-bottom: 0;
  padding-bottom: 15px;
  border-bottom: 1px solid #E1E9EF;
}

table.rating-table {
  color: #000;
  border-spacing: 3px;
  margin-top: -4px;
  margin-left: -4px;
  width: 300px;
}

td.rating-table-cell {
  line-height: 40px;
  width: 30px;
  text-align: center;
  background-color: #eee;
  font-weight: bold;
  font-size: 18px;
  border-radius: 0px 0px 3px 3px;
  border: 4px solid #eee;
  cursor: pointer;
}

td.highlight {
  background-color: #ccc;
}

ul {
  line-height: 1.5em;
  padding-left: 1.25em;
}

.current-level {
  margin-bottom: 2em;
}

.next-level {
  font-size: .85em;
  background-color: rgba(225, 233, 239, .5);
  padding: .5em 1.5em;
  border-radius: 5px;
  margin-bottom: 2em;
}

.point-value {
  font-style: italic;
  font-size: 12px;
}

.add-button, .add-button-large, .cancel-button {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  vertical-align: center;
  padding: 3px;
  border-radius: 4px;
  margin-left: 10px;
  height: 20px;
  background-color: #99cc99;
}

.add-button-large {
  margin-top: 10px;
  width: 150px;
}

.cancel-button {
  background-color: #cccccc;
  margin-left: 12px;
}
