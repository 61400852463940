body {
  font-family: Helvetica;
  color: #4c5b67;
  padding: 0;
  margin: 0;
}

.grid {
  width: 90%;
  max-width: 1300px;
  margin: 0 auto;
}

.logo {
  margin: 1.5em auto;
  max-width: 150px;
}

.header {
  padding: 2em 0;
  background-color: #1A262F;
}

.header-form {
  display: flex;
}

.header-form form {
  display: flex;
  align-items: stretch;
  /*justify-content: center;*/
  flex-wrap: nowrap;
  width: 100%;
}

.grid-rows {
  width: 100%;
}

.form-row-1, .form-row-2 {
  display: flex;
}

.form-row-1 {
  margin-bottom: 24px;
}

.form-row-2 {
  justify-content: space-between;
}

.person {
  float: left;
  width: 60%;
}

.evaluator {
  background-color: #4c5b67;
  width: 30%;
}

.name-label {
  color: #fff;
  display: inline;
  font-size: 24px;
  margin-right: 24px;
  white-space: nowrap;
  line-height: 42px;
}

.name-input {
  border: 0;
  border-bottom: 2px solid #7e97a0;
  background: transparent;
  color: #fff;
  display: inline;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
  width: 100%;
  padding: .25em .125em;
}

.evaluator-input {
  border: 0;
  background: transparent;
  display: inline-block;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  padding: 1em .125em;
}

.name-input:hover, .name-input:focus, .name-input:active, .evaluator-input:hover, .evaluator-input:focus, .evaluator-input:active {
  border-color: #11a9a1;
  background-color: rgba(17, 169, 161, .125);
  outline: 0;
}

.form-input {
  display: inline-block;
  font-size: 16px;
  color: #fff;
}

.form-input label {
  margin-right: 10px;
}

.form-input + .form-input {
  margin-left: 10px;
}

.select-wrapper {
  display: block;
  height: 3em;
  border-bottom: 2px solid #7e97a0;
}

.select-wrapper:active, .select-wrapper:focus, .select-wrapper:hover {
  border-color: #11a9a1;
  background-color: rgba(17, 169, 161, .125);
  cursor: pointer;
}

select {
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  /*min-width: 270px;*/
  padding: .5em .25em;
  background: transparent;
  height: 3em;
  border: 0;
}

select.small {
  min-width: 100px;
}

option {
  color: #000000;
}

.input-wrapper {
  display: block;
  height: 3em;
  border-bottom: 2px solid #7e97a0;
}

a {
  color: #109C95;
  text-decoration: none;
}

a:active, a:hover, a:focus {
  text-decoration: underline;
}

.scoring {
  display: flex;
  flex-wrap: nowrap;
}

.panel {
  padding: 2em;
}

.panel:first-of-type {
  margin-right: 2em;
  padding-right: 4em;
  border-right: 1px solid #E1E9EF;
  width: 360px;
}

.career-summary-wrapper {
  color: #fff;
  padding: 2em 0;
  background-color: #1A262F;
}

.footer {
  color: #fff;
  padding: 2em 0;
  background-color: #1A262F;
  border-top: 1px solid #4c5b67;
  text-align: center
}

.footer span {
  margin: 0 2em;
}

/* Tablet */
@media screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (-webkit-min-device-pixel-ratio: 2) {
  .grid.scoring {
    margin-top: 2em;
  }

  .scoring {
    column-gap: 2em;
  }

  .panel {
    padding: 0;
  }

  .panel:first-of-type {
    margin-right: 0px;
    padding-right: 2em;
    border-right: 1px solid #E1E9EF;
    width: 330px;
  }
}

@media screen and (min-width: 1050px) {
  .evaluator-input, select {
    min-width: 270px;
  }
}
