.chart {
  margin-top: 0px;
}

.summary {
  display: block;
  text-align: center;
  font-weight: bold;
}

.summary__meta {
  display: block;
  text-align: center;
}

table.graded-level {
  margin: 8px 0 0;
  border-spacing: 0;
  width: 360px;
}

tr.graded:first-child td {
  height: 0;
}

tr.graded-levels {
  height: 20px;
  background-color: #e1e9ef;
}

.graded-current-level {
  font-size: 12px;
  text-align: left;
  padding-left: 8px;
  border-left: 1px solid #ccc;
}

.graded-next-level {
  font-size: 12px;
  text-align: right;
  padding-right: 8px;
  border-right: 1px solid #ccc;
}

.graded-total-full {
  background-color: #4c5b67;
  border-left: 1px solid #ccc;
}

.graded-total-empty {
  background-color: #e1e9ef;
  border-right: 1px solid #ccc;
}

.graded-levels {
  width: 10%;
}

/* Tablet */
@media screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (-webkit-min-device-pixel-ratio: 2) {

  .chart, table.graded-level {
    width: 330px;
  }
}

